<template>
  <div class="picture-article" :style="picAndArt">
    <div class="img-box">
      <!-- <img
        src="../../../../../../Desktop/11.png"
        :style="imageStyl"
      /> -->
    </div>
    <div class="title" :style="mainTitle">{{ receiveProps.articleContentData.articleName }}</div>
    <div class="inner-text" :style="halfTitle">
      {{receiveProps.articleContentData}}
    </div>
  </div>
</template>

<script>
export default {
  name: "pictureAndArticle",
  props: {
      receiveProps:{
          type: Object,
          default: ()=>{}
      }
  },
  computed: {
    picAndArt() {
        let {blockMargeTop, blockMargeBottom, blockBGClolor} = this.receiveProps.specialStyle
      return {
        "--all-margin-top": blockMargeTop,
        "--all-margin-bottom": blockMargeBottom,
        "--all-background-color": blockBGClolor,
        // "--all-margin-left": "20px",
        // "--all-margin-right": "20px",
        // "--all-border-width": "3px",
        // "--all-box-shadow": "",
        // "--all-border-radius": "2px",
      };
    },
    imageStyl() {
      let {imgBackgroundColor, imgBorderColor, imgBorder, imgShadow, imgRadius} = this.receiveProps.specialStyle
      return {
        "--image-border-width": imgBorder? "1px": '',
        "--image-border-color": imgBorderColor,
        "--image-box-shadow": imgShadow? "0px 0px 16px 0px rgba(0, 0, 0, 0.1);": "",
        "--image-border-radius": imgRadius?"8px": '',
        "--image-background-color": imgBackgroundColor,
      };
    },
    mainTitle() {
      let {titleAlign, titleBold, titleColor, titleSize} = this.receiveProps.specialStyle
      return {
        "--main-margin-bottom": "10px",
        "--main-font-color": titleColor,
        "--main-font-size": titleSize,
        "--main-font-weight": titleBold? "700":'',
        "--main-text-align": titleAlign,
      };
    },
    halfTitle() {
        let {descSize, descColor, descBold, descAlign} = this.receiveProps.specialStyle
      return {
        "--half-font-color": descColor,
        "--half-font-size": descSize,
        "--half-font-weight": descBold? "700": '',
        "--half-text-align": descAlign,
      };
    },
  },
  data() {
    return {
     };
  },
 
  methods: {
  },
};
</script>

<style lang="less" scoped>
.picture-article {
  width: 1200px;
  height: 204px;
  margin: 0 auto;
  position: relative;
  margin-top: var(--all-margin-top);
  margin-bottom: var(--all-margin-bottom);
  background-color: var(--all-background-color);
//   margin-left: var(--all-margin-left);
//   margin-right: var(--all-margin-right);
//   border-width: var(--all-border-width);
//   border-radius: var(--all-border-radius);
  .img-box {
    z-index: -1;
    width: 1200px;
    height: 204px;
    position: absolute;
    img {
      width: 100%;
      height: 100%;
      border-width: var(--image-border-width);
      border-color: var(--image-border-color);
      border-style: solid;
      box-shadow: var(--image-box-shadow);
      border-radius: var(--image-border-radius);
      background-color: var(--image-background-color);
    }
  }
  .title {
    display: flex;
    margin-bottom: var(--main-margin-bottom);
    color: var(--main-font-color);
    font-size: var(--main-font-size);
    font-weight: var(--main-font-weight);
    text-align: var(--main-text-align);
  }
  .inner-text {
    display: flex;
    color: var(--half-font-color);
    font-size: var(--half-font-size);
    font-weight: var(--half-font-weight);
    text-align: var(--half-text-align);
  }
}
</style>